@import 'src/utils/utils';

.service {
  @include zero;
  background-color: $Main-2;
  overflow: hidden;
  margin-bottom: 30px;

  @media(min-width: $md) {
    margin-top: 30px;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 20px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    @include box;
  }

  &__accent {
    @include zero;
    height: 52px;

    @media(min-width: $xxl) {
      height: 62px;
    }
  }

  &__content {
    @include zero;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    padding-top: 35px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;

    @media(min-width: $xxl) {
      display: grid;
      grid-template-columns: auto 175px;
      align-items: center;
      padding-top: 42px;
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 40px;
      column-gap: 20px;
      row-gap: 30px;
    }
  }

  &__icon {
    position: absolute;
    top: -27px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    padding: 5px;
    border-radius: 10px;
    background: $Main-2;
    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));

    @media(min-width: $xxl) {
      top: -27px;
      left: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
    }

    svg {
      width: 28px;
      height: 28px;
      stroke: $Main-1;

      @media(min-width: $xxl) {
        width: 28px;
        height: 28px;
      }
    }
  }

  &__header {
    @include zero;
    @include Head-60-bold;
    word-break: break-word;
  }

  &__subscription {
    @include zero;
    position: relative;
    padding: 5px;
    border: 1px solid $Main-5;
    border-radius: 10px;
    background-color: transparent;
    cursor: pointer;
    transition: border-color 0.3s;

    @media(min-width: $xxl) {
      padding-top: 10px;
      padding-right: 15px;
      padding-bottom: 10px;
      padding-left: 15px;
      column-gap: 10px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      @include zero;
      @include Text-14-reg;
    }

    &--subscribed {
      @media(min-width: $lg) {
        &:hover {
          border-color: $Corporate-2;
  
          .service__subscription-content {
            opacity: 0;
          }
  
          .service__subscription-content--unsubscribe {
            opacity: 1;
          }
        } 
      }
    }
  }

  &__subscription-content {
    display: flex;
    align-items: center;
    column-gap: 5px;
    opacity: 1;
    transition: opacity 0.3s;
    

    &--unsubscribe {
      position: absolute;
      top: 5px;
      opacity: 0;

      @media(min-width: $xxl) {
        top: 10px;
      }

      span {
        color: $Corporate-2;
      }
    }
  }

  &__counter {
    @include zero;
    @include Text-14-reg;
    color: $Main-1;

    @media(min-width: $xxl) {
      grid-column-start: 1;
    }
  }
}